import React from 'react';
import {Box, Flex, Heading, Text} from "@chakra-ui/react";
import config from "../config";
import { useStore } from '@nanostores/react';
import {footerTextFont, smallerHeadingFont} from "../stores/fonts.store";
import {whiteColorText, blueColorBackground} from "../stores/contrast.store";

const Footer = () => {
    const textSize = useStore(footerTextFont);
    const headingSize = useStore(smallerHeadingFont);
    const blueBackground = useStore(blueColorBackground);
    const whiteColor = useStore(whiteColorText);
    return (
        <Flex p={5} bgColor={blueBackground} color={whiteColor} flexDir={{base: 'column', lg: 'row'}}  justify={{base: 'center'}} columnGap={{lg:'50px'}}>
            <Box>
           <Heading fontWeight='thin'  fontSize={`${headingSize}px`} letterSpacing={2} pt={'10px'} pb={'20px'} borderBottom={'1px solid white'}>Dane Fundacji</Heading>
           <Text p={'10px'} fontSize={`${textSize}px`} letterSpacing={config.regularText.letterSpacing} lineHeight={config.regularText.lineHeight}>Fundacja Bezdomniaki
           </Text>
            <Text p={'10px'} fontSize={`${textSize}px`} letterSpacing={config.regularText.letterSpacing} lineHeight={config.regularText.lineHeight}>ul. Rembielińska 10a/50
            </Text>
            <Text p={'10px'} fontSize={`${textSize}px`} letterSpacing={config.regularText.letterSpacing} lineHeight={config.regularText.lineHeight}>03 - 343 Warszawa
            </Text>
            <Text p={'10px'} fontSize={`${textSize}px`} letterSpacing={config.regularText.letterSpacing} lineHeight={config.regularText.lineHeight}>NIP 524 275 80 16
            </Text>
            <Text p={'10px'} fontSize={`${textSize}px`} letterSpacing={config.regularText.letterSpacing} lineHeight={config.regularText.lineHeight}>REGON 146587756
            </Text>
            <Text p={'10px'} fontSize={`${textSize}px`} letterSpacing={config.regularText.letterSpacing} lineHeight={config.regularText.lineHeight}>KRS 0000448272
            </Text>
            </Box>
            <Box>
                <Heading fontWeight='thin'  fontSize={`${headingSize}px`} letterSpacing={2} pt={'10px'} pb={'20px'} borderBottom={'1px solid white'}>KONTO BANKOWE</Heading>
                <Text p={'10px'} fontSize={`${textSize}px`} letterSpacing={config.regularText.letterSpacing} lineHeight={config.regularText.lineHeight}>Pekao SA</Text>
                <Text p={'10px'} fontSize={`${textSize}px`} letterSpacing={config.regularText.letterSpacing} lineHeight={config.regularText.lineHeight}>nr: 53 1240 1082 1111 0010 4885 5029</Text>
                <Text p={'10px'} fontSize={`${textSize}px`} letterSpacing={config.regularText.letterSpacing} lineHeight={config.regularText.lineHeight}>SWIFT: PKOPPLPW</Text>
                <Text p={'10px'} fontSize={`${textSize}px`} letterSpacing={config.regularText.letterSpacing} lineHeight={config.regularText.lineHeight}>paypal: fundacjabezdomniaki@gmail.com</Text>
            </Box>
        </Flex>
    );
};

export default Footer;