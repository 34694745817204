import React from 'react';
import {Box, Flex, Heading, Image, Text} from "@chakra-ui/react";
import dogImage from '../assets/images/dog_with_phone.png';
import { FaPhoneVolume } from 'react-icons/fa';
import { AiTwotoneMail } from 'react-icons/ai';
import {useStore} from "@nanostores/react";
import {headingFont, smallerHeadingFont} from "../stores/fonts.store";
import {blueColorText, blueColorBackground, whiteColorText} from "../stores/contrast.store";

const Contact = () => {
    const headingFontSize = useStore(headingFont);
    const textFontSize = useStore(smallerHeadingFont);
    const blueText = useStore(blueColorText);
    const blueBackground = useStore(blueColorBackground);
    const whiteText = useStore(whiteColorText);
    return (
        <Box pt={'20px'} pb={'20px'}>
            <Heading color={blueText} fontWeight='thin' letterSpacing={2} pt={'10px'} pb={'20px'} fontSize={`${headingFontSize}px`}>Kontakt</Heading>
            <Flex flexDir={{base: 'column', lg: 'row-reverse'}} bgColor={blueBackground} p={'10px'} align={'center'}>
                <Image src={dogImage} boxSize={{base: '250px', lg:'600px'}} objectFit='scale-down'/>
                <Flex color={whiteText} fontSize={`${textFontSize}px`} flexDir={'column'} align={'center'} width={{lg: '400px'}}>
                    <Text pt={'10px'} pb={'10px'}>Poniżej możecie znaleźć nasz numer telefonu i adres mailowy. Dane rejestrowe oraz informacje o koncie bankowym znajdują się w stopce strony.</Text>
                    <Flex p={'10px'} columnGap={'5px'}><FaPhoneVolume/> +48 666 345 888</Flex>
                    <Flex p={'10px'} fontSize={{base: `${textFontSize - 5}px`, lg:`${textFontSize}px`}} columnGap={'5px'}><AiTwotoneMail/> fundacjabezdomniaki@gmail.com</Flex>
                </Flex>
            </Flex>
        </Box>
    );
};

export default Contact;