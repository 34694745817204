import React from 'react';
import WhatWeDo from "../components/main-page/what-we-do";
import HowYouCanHelp from "../components/main-page/how-you-can-help";
import Proteges from "../components/main-page/proteges";
import {Box} from "@chakra-ui/react";

const Home = () => {
    return (
        <Box>
            <WhatWeDo/>
            <HowYouCanHelp/>
            <Proteges/>
        </Box>

    );
};

export default Home;