import React from 'react';
import {Box, Heading, Image, Text, Button, Link} from "@chakra-ui/react";
import image from '../../assets/images/how_can_you_help.jpg'
import config from "../../config";
import { useStore } from '@nanostores/react';
import {smallerHeadingFont} from '../../stores/fonts.store';
import {textFont} from '../../stores/fonts.store';
import {blackColorText, blueColorText} from "../../stores/contrast.store";

const HowYouCanHelp = () => {
    const headingFontSize = useStore(smallerHeadingFont);
    const textFontSize = useStore(textFont);
    const blueColor = useStore(blueColorText);
    const blackColor = useStore(blackColorText);
    return (
        <Box p={5}>
            <Heading color={blueColor}  fontFamily={'Roboto'} fontWeight='semibold' letterSpacing={2} pt={'10px'} pb={'20px'} fontSize={{base: `${headingFontSize}px`, md: `${headingFontSize + 5}px`}}>Sprawdź Jak Możesz Pomóc</Heading>
            <Image src={image}/>
            <Text color={blackColor} p={'10px'} fontSize={{base: `${textFontSize}px`, md: `${textFontSize + 5}px`}} letterSpacing={config.regularText.letterSpacing} lineHeight={config.regularText.lineHeight}>To nie tylko 1% podatku, istnieje wiele więcej form pomocy, które są w stanie odmienić los naszych podopiecznych. Czasami skromny gest potrafi zrobić wielką różnicę.
                Zobacz jak możesz sprawić, aby życie zwierzaka mogło stać się lepsze.
            </Text>
            <Button as={Link} colorScheme='linkedin' fontSize={`${headingFontSize}px`} p={'20px'} href={'/pomoc'}>Chcę pomóc</Button>
        </Box>
    );
};

export default HowYouCanHelp;