import React from 'react';
import {Flex, Image, Text} from "@chakra-ui/react";
import image from '../assets/images/logo-fundacji.jpg';
import { useStore } from '@nanostores/react';
import {smallerHeadingFont} from '../stores/fonts.store';
import {blackColorText} from "../stores/contrast.store";

const Header = () => {
    const fontSize = useStore(smallerHeadingFont);
    const blackColor = useStore(blackColorText);
    return (
        <Flex p={{base: '10px'}} flexDir={{base: 'column', lg: 'row'}} alignItems='center' justify={{base: 'center'}}>
            <Image boxSize='250px' src={image} alt='Logo fundacji bezdomniaki' />
            <Text fontSize={{base: `${fontSize}px`, lg:`${fontSize + 25}px`}} color={blackColor} pl={{lg: '20px'}} fontFamily='Courgette, cursive'>I Ty także możesz pomóc!</Text>
        </Flex>
    );
};

export default Header;