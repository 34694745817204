import React from 'react';
import {Flex, Heading, Text} from "@chakra-ui/react";
import boneBackground from '../../assets/images/paw_bone_background.jpg';
import config from "../../config";
import { useStore } from '@nanostores/react';
import {smallerHeadingFont} from '../../stores/fonts.store';
import {textFont} from '../../stores/fonts.store';
import {blueColorBackground, useBgImage, whiteColorText} from "../../stores/contrast.store";

const WhatWeDo = () => {
    const headingFontSize = useStore(smallerHeadingFont);
    const textFontSize = useStore(textFont);
    const whiteColor = useStore(whiteColorText);
    const bgColor = useStore(blueColorBackground);
    const useImage = useStore(useBgImage);
    return (
        <Flex flexDir='column' justify='center' align='center' bgColor={bgColor} bgImage={`${useImage && boneBackground}`} p={5} color={whiteColor}>
            <Heading lineHeight={1.2} fontFamily='Roboto' fontWeight='thin' letterSpacing={4} fontSize={{base: `${headingFontSize}px`, md: `${headingFontSize + 5}px`}}>Każde zwierze zasługuje na dom!</Heading>
            <Text pt='15px' fontSize={{base: `${textFontSize}px`, md: `${textFontSize + 5}px`}} lineHeight={config.regularText.lineHeight} letterSpacing={config.regularText.letterSpacing}> Fundacja Bezdomniaki zajmuje się ochroną i opieką nad bezdomnymi zwierzętami, takimi jak psy i koty, poprzez adopcję, organizację domów tymczasowych i realizację sterylizacji / kastracji. Prowadzimy także kampanie i działania edukacyjne, aby zwiększyć świadomość społeczeństwa na temat bezdomnych zwierząt i jak można im pomóc.</Text>
        </Flex>
    );
};

export default WhatWeDo;