import React from 'react';
import {Box, Button, Heading, Link} from "@chakra-ui/react";
import firstImage from '../../assets/images/podopieczni/1.jpg';
import secondImage from '../../assets/images/podopieczni/2.jpg';
import thirdImage from '../../assets/images/podopieczni/3.jpg';
import fourthImage from '../../assets/images/podopieczni/4.jpg';
import { useStore } from '@nanostores/react';
import {smallerHeadingFont} from '../../stores/fonts.store';
import {pinkColorText} from "../../stores/contrast.store";
import  ImageGallery from 'react-image-gallery'

const images = [
    {
        original: firstImage,
    },
    {
        original: secondImage,
    },
    {
        original: thirdImage,
    },
    {
        original: fourthImage,
    },
];

const Proteges = () => {
    const headingFontSize = useStore(smallerHeadingFont);
    const pinkColor = useStore(pinkColorText);
    return (
        <Box>
            <Heading color={pinkColor} fontFamily={'Roboto'} fontWeight='semibold' letterSpacing={2} pt={'10px'} pb={'20px'} fontSize={{base: `${headingFontSize}px`, md: `${headingFontSize + 5}px`}}>Nasi podopieczni</Heading>
            <ImageGallery items={images} showFullscreenButton={false} autoPlay={true}/>
            <Button as={Link} href={'/zwierzeta'} mt={'20px'} mb={'20px'} colorScheme='pink' fontSize={`${headingFontSize}px`} p={'20px'}>Wszyscy podopieczni</Button>
        </Box>
    );
};

export default Proteges;