import React from 'react';
import {Box, Heading} from "@chakra-ui/react";
import {useStore} from "@nanostores/react";
import {headingFont} from "../stores/fonts.store";
import {blueColorText} from "../stores/contrast.store";

const Help = () => {
    const headingFontSize = useStore(headingFont);
    const blueColor = useStore(blueColorText);
    return (
        <Box>
            <Heading fontSize={`${headingFontSize}px`} color={blueColor} fontWeight='thin' letterSpacing={2} pt={'10px'} pb={'20px'}>Pomoc</Heading>
        </Box>
    );
};

export default Help;