import React from 'react';
import {Box, Heading} from "@chakra-ui/react";

const Animals = () => {
    return (
        <Box>
            <Heading color={'rgb(0, 153, 215)'} fontWeight='thin' letterSpacing={2} pt={'10px'} pb={'20px'}>Nasi podopieczni</Heading>
        </Box>
    );
};

export default Animals;