import {createBrowserRouter} from "react-router-dom";
import Home from "../pages/Home";
import React from "react";
import Contact from "../pages/Contact";
import Help from "../pages/Help";
import Animals from "../pages/Animals";

export const router = createBrowserRouter([
    {
        path: "/",
        element: <Home/>,
    },
    {
        path: "/kontakt",
        element: <Contact/>,
    },
    {
        path: "/pomoc",
        element: <Help/>,
    },
    {
        path: "/zwierzeta",
        element: <Animals/>,
    },
]);